.linkedin-component {
  margin: 20px 0;
}

.linkedin-button-container {
  display: flex;
  justify-content: center;
}

.linkedin-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkedin-login-button {
  background-color: #0077B5;
  color: white;
}

.linkedin-login-button:hover {
  background-color: #006097;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.linkedin-logout-button {
  background-color: #f3f3f3;
  color: #333;
}

.linkedin-logout-button:hover {
  background-color: #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.linkedin-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.linkedin-button i {
  margin-right: 10px;
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-right: 10px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.linkedin-error-message {
  color: #d32f2f;
  text-align: center;
  margin-top: 10px;
}
