@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --primary-color: #ffffff;
  --secondary-color: #0A403C;
  --background-color: #000000;
  --text-color: #ffffff;
  --accent-color: #0F766E;
  --border-color: #134E4A;
  --sidebar-bg: #0F172A;
  --sidebar-hover: #0D9488;
  --sidebar-active: #0F766E;
  --border-radius: 12px;
  --transition-duration: 0.3s;
  --box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  --gradient: linear-gradient(169deg, #000000 0%, #0A403C 100%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'League Spartan', sans-serif;
  line-height: 1.6;
  transition: background-color var(--transition-duration) ease;
}

/* Contenedor principal */
.app-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar mejorado */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: var(--sidebar-bg);
  color: var(--primary-color);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  transition: all var(--transition-duration) ease;
  overflow-x: hidden;
  z-index: 1000;
  border-right: 1px solid var(--border-color);
}

.sidebar.closed {
  width: 60px;
}

.toggle-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 20px;
  cursor: pointer;
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-info {
  padding: 20px;
  background-color: var(--secondary-color);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.sidebar-nav {
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar-button {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  transition: all var(--transition-duration) ease;
  text-align: left;
}

.sidebar-button:hover, .sidebar-button.active {
  background-color: var(--sidebar-hover);
}

.sidebar-button svg {
  font-size: 20px;
  margin-right: 15px;
  min-width: 20px;
}

.submenu-toggle {
  justify-content: space-between;
}

.submenu-toggle .chevron {
  transition: transform var(--transition-duration) ease;
}

.submenu-toggle[aria-expanded="true"] .chevron {
  transform: rotate(180deg);
}

.submenu-items {
  background-color: rgba(15, 118, 110, 0.1);
  overflow: hidden;
  max-height: 0;
  transition: max-height var(--transition-duration) ease;
}

.submenu-items.open {
  max-height: 500px;
}

.sidebar-button.sub-item {
  padding-left: 55px;
  font-size: 14px;
}

.logout-button {
  background-color: #991B1B;
  margin-top: auto;
}

.logout-button:hover {
  background-color: #7F1D1D;
}

.sidebar.closed .sidebar-button span,
.sidebar.closed .user-info span {
  display: none;
}

.sidebar.closed .submenu-items {
  display: none;
}

/* Contenido principal */
.main-content {
  flex-grow: 1;
  margin-left: 250px;
  padding: 30px;
  transition: margin-left var(--transition-duration) ease;
}

.sidebar.closed + .main-content {
  margin-left: 60px;
}

/* Estilos del cuestionario */
.questionnaire-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  background-color: rgba(15, 118, 110, 0.05);
  border-radius: var(--border-radius);
  padding: 40px;
  box-shadow: var(--box-shadow);
}

.question {
  margin-bottom: 60px;
  padding: 30px;
  border-radius: var(--border-radius);
  transition: all var(--transition-duration) cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0.3;
  position: relative;
  overflow: hidden;
}

.question.active {
  opacity: 1;
  background-color: rgba(15, 118, 110, 0.1);
  box-shadow: var(--box-shadow);
}

.question.inactive {
  opacity: 0.5;
}

.question::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--gradient);
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform .5s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.question.active::before {
  transform: scaleX(1);
}

.question-number {
  font-size: 18px;
  margin-bottom: 10px;
  color: var(--accent-color);
  font-weight: 500;
}

.question-text {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 25px;
  color: var(--primary-color);
  line-height: 1.4;
}

/* Estilos de input y botones */
.input-field {
  width: 100%;
  background-color: rgba(15, 118, 110, 0.05);
  border: none;
  border-bottom: 2px solid var(--border-color);
  color: var(--primary-color);
  font-size: 1.1rem;
  padding: 12px 15px;
  transition: all var(--transition-duration) ease;
  border-radius: 6px 6px 0 0;
}

.input-field:focus {
  outline: none;
  border-bottom-color: var(--accent-color);
  background-color: rgba(15, 118, 110, 0.1);
  box-shadow: 0 4px 6px rgba(15, 118, 110, 0.2);
}

.navigation-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.nav-button {
  padding: 12px 28px;
  background: var(--gradient);
  color: var(--primary-color);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all var(--transition-duration) ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 4px 6px rgba(15, 118, 110, 0.2);
}

.nav-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(15, 118, 110, 0.3);
}

/* Estilos de opciones */
.options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 25px;
}

.option-button {
  background-color: rgba(15, 118, 110, 0.05);
  border: 2px solid var(--border-color);
  color: var(--primary-color);
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: all var(--transition-duration) cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 calc(50% - 15px);
  min-width: 150px;
}

.option-button.selected, .option-button:hover {
  background: var(--gradient);
  color: var(--primary-color);
  border-color: var(--accent-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(15, 118, 110, 0.2);
}

.option-letter {
  font-weight: bold;
  margin-right: 10px;
  font-size: 1.2em;
}

/* Responsive design */
@media (max-width: 1024px) {
  .sidebar {
    width: 60px;
  }

  .sidebar.open {
    width: 250px;
  }

  .sidebar-button span,
  .user-info span {
    display: none;
  }

  .sidebar.open .sidebar-button span,
  .sidebar.open .user-info span {
    display: inline;
  }

  .main-content {
    margin-left: 60px;
  }

  .sidebar.open + .main-content {
    margin-left: 250px;
  }
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
    padding: 20px;
  }

  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
    width: 250px;
  }

  .questionnaire-container {
    padding: 30px;
  }

  .question {
    padding: 25px;
  }

  .question-text {
    font-size: 22px;
  }

  .options-container {
    flex-direction: column;
  }

  .option-button {
    width: 100%;
    flex: 1 0 100%;
  }

  .navigation-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .nav-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .questionnaire-container {
    padding: 20px;
  }

  .question-text {
    font-size: 20px;
  }

  .question-number {
    font-size: 16px;
  }

  .post-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .post-platform {
    margin-top: 10px;
  }
}

/* Animaciones */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.question, .post, .interests-container {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Scrollbar personalizado */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--sidebar-hover);
}

@keyframes custom-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animate-custom-spin {
  animation: custom-spin 1s linear infinite;
}

/* Estilos para dropdowns */
select.input-field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  cursor: pointer;
  padding-right: 2.5rem;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1em;
}

select.input-field:hover {
  background-color: rgba(15, 118, 110, 0.2);
}

select.input-field:focus {
  outline: none;
  border-bottom-color: var(--accent-color);
  background-color: rgba(15, 118, 110, 0.3);
  box-shadow: 0 4px 6px rgba(15, 118, 110, 0.2);
}

select.input-field option {
  background-color: var(--secondary-color);
  color: var(--primary-color);
  padding: 12px;
}

select.input-field option:hover {
  background-color: var(--accent-color);
}

select.input-field option:checked {
  background-color: var(--accent-color);
}

/* Estilo para el placeholder del select */
select.input-field option:first-child {
  color: rgba(255, 255, 255, 0.7);
}

/* Asegurar que el dropdown menu tenga el estilo correcto */
select.input-field::-ms-expand {
  display: none;
}

select.input-field:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--primary-color);
}


/* Responsive styles */
@media (max-width: 1024px) {
  .main-content {
    margin-left: 0;
  }

  aside {
    position: fixed;
    transform: translateX(-100%);
    will-change: transform;
  }

  aside.translate-x-0 {
    transform: translateX(0);
  }
}

/* iOS specific adjustments */
@supports (-webkit-touch-callout: none) {
  .mobile-header {
    padding-top: env(safe-area-inset-top);
    height: calc(60px + env(safe-area-inset-top));
  }

  aside {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .has-mobile-header {
    padding-top: calc(60px + env(safe-area-inset-top));
  }
}

/* Prevent scroll when mobile menu is open */
body.menu-open {
  overflow: hidden;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.question, .post, .interests-container {
  animation: fadeIn 0.5s ease-out forwards;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--background-color);
}

::-webkit-scrollbar-thumb {
  background: var(--accent-color);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--sidebar-hover);
}

@keyframes custom-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.animate-custom-spin {
  animation: custom-spin 1s linear infinite;
}